import ApplicationController from "../../../../plus/src/controllers/application_controller";
import { debounce, omitBy, isNil } from "lodash";
import { get, put, destroy } from "@rails/request.js";
import { v4 as uuidv4 } from "uuid";

export class BaseGridController extends ApplicationController {
  tableLayout = "fitData";
  static targets = ["table", "actions"];
  static values = {
    edits: Object,
  };

  async connected() {
    this.editedCells = [];
    this.deletedCells = [];
    this.mediaTypesAndSubtypes = await this.fetchMediaTypesAndSubtypes();
    this.unitScreenSubtypes = await this.fetchUnitScreenSubtypes();
    this.saveDataDebounced = debounce(this.saveData, 100);
    this.deleteDataDebounced = debounce(this.deleteData, 100);
    this.getMatchingSubtypes = this.getMatchingSubtypes.bind(this);
    this.getMatchingScreenSubtypes = this.getMatchingScreenSubtypes.bind(this);
    this.handleUnitTypeChanges = this.handleUnitTypeChanges.bind(this);
    this.disableTable = this.disableTable.bind(this);
    this.enableTable = this.enableTable.bind(this);

    const data = await this.fetchData();
    this.tableData = data;

    const config = await this.fetchConfig();
    const configuredColumns = this.customizeColumns(config.columns, config.settings);
    this.initializeHandsontable(configuredColumns, this.tableData);
    this.checkEmptyRowsInterval = setInterval(() => {
      this.cleanupEmptyRows();
    }, 5000);
  }

  disconnect() {
    window.HandsontableLatest.hooks.destroy();
    clearInterval(this.checkEmptyRowsInterval);
  }

  styledCellRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    window.HandsontableLatest.renderers.BaseRenderer.apply(this, arguments);
    const errors = hotInstance.getDataAtRowProp(row, "errors");

    if (errors && errors.some(error => error.key === prop)) {
      td.classList.add("cell-error");
    }
    td.innerHTML = value;
  }

  internationalDateRenderer(hotInstance, td, row, column, prop, value, cellProperties) {
    window.HandsontableLatest.renderers.getRenderer("styled_cell").apply(this, arguments);
    const parsedDate = moment(value, 'MM/DD/YYYY');
    if (parsedDate.isValid()) {
      return td.innerHTML = parsedDate.format('DD/MM/YYYY');
    } else {
      return td.innerHTML = value;
    }
  }

  updateEditedCells(rowValue) {
    const existingIndex = this.editedCells.findIndex(cell => cell.rowIndex === rowValue.rowIndex);

    if (existingIndex !== -1) {
      // Merge edit with existing edits on same row
      this.editedCells[existingIndex] = { ...this.editedCells[existingIndex], ...rowValue };
    } else {
      // Push rowValue to editedCells
      this.editedCells.push(rowValue);
    }
  }
  async initializeHandsontable(columns, data) {
    window.HandsontableLatest.renderers.registerRenderer("styled_cell", this.styledCellRenderer);
    window.HandsontableLatest.renderers.registerRenderer("international_date_cell", this.internationalDateRenderer);
    const hot = new window.HandsontableLatest(this.tableTarget, {
      columns: columns,
      contextMenu: ["remove_row", "freeze_column", "unfreeze_column"],
      dataSchema: () => {
        return { row_id: uuidv4() };
      },
      rowHeights: 23,
      rowHeaders: true,
      autoRowSize: false,
      wordWrap: false,
      columnSorting: false,
      colWidths: 150,
      colHeaders: true,
      stretchH: "all",
      // height: '100%',
      width: "100%",
      autoWrapRow: false,
      autoWrapCol: false,
      manualColumnFreeze: true,
      minSpareRows: 1,
      isEmptyRow: function(row) {
        const rowData = this.getSourceDataAtRow(row);
        const rowKeys = Object.keys(rowData).toString();
        return rowKeys === "row_id";
      },
      fixedColumnsStart: 2, // start with 2 since col 1 is the hidden row_id, 2 should be the supplier_face_id
      hiddenColumns: {
        copyPasteEnabled: false,
        indicators: false,
        columns: [0], // hide the row_id column
      },
      licenseKey: "non-commercial-and-evaluation", // for non-commercial use only
    });
    window.HandsontableLatest.hooks.add("afterChange", (changes, source) => {
      if (source === "upsertRow") {
        return;
      }
      if (source == "edit" || source == "CopyPaste.paste" || source == "Autofill.fill") {
        if (changes) {
          this.handleUnitTypeChanges(changes);
          changes.forEach(([row, prop, oldVal, newVal]) => {
            const currentRow = hot.getSourceDataAtRow(row);
            const rowValue = Object.assign(currentRow, { rowIndex: row, [prop]: newVal });
            this.updateEditedCells(rowValue, row);
          });
          this.saveData(true);
        }
      }
    });
    window.HandsontableLatest.hooks.add("beforeRemoveRow", (index, amount, physicalRows, source) => {
      let rowIds = physicalRows.map(row => {
        return { row_id: hot.getDataAtRowProp(row, "row_id") };
      });

      this.deleteData(rowIds);
    });

    hot.loadData(data);
    this.search = hot.getPlugin("search");
    window.hot = hot;
    this.hot = hot;
    this.initializeSubTypes();
    this.initializeScreenSubTypes();
  }

  handleUnitTypeChanges(changes) {
    changes.map(change => {
      const [row, prop, _oldValue, newValue] = change;
      if (prop == "unit_type") {
        this.setSubtypeSource(row, newValue);
      }

      if (prop == "screen_type") {
        this.setScreenSubtypeSource(row, newValue);
      }
    });
  }

  async setSubtypeSource(row, value) {
    const newSubtypeSource = this.mediaTypesAndSubtypes.find(
      t => t.name == value || t.value == value || t.special_cases.includes(value),
    );
    if (newSubtypeSource) {
      const options = newSubtypeSource.media_subtypes.map(e => e.name);
      options.unshift("");
      this.hot.setCellMeta(row, this.hot.propToCol("unit_subtype"), "source", options);
    }
  }

  async setScreenSubtypeSource(row, value) {
    const newScreenSubtypeSource = this.unitScreenSubtypes.filter(t => t.screen_type == value);
    if (newScreenSubtypeSource) {
      const options = newScreenSubtypeSource.map(e => e.name);
      options.unshift("");
      this.hot.setCellMeta(row, this.hot.propToCol("screen_subtype"), "source", options);
    }
  }

  initializeSubTypes() {
    this.hot.getSourceData().map((row, index) => this.setSubtypeSource(index, row.unit_type));
  }

  initializeScreenSubTypes() {
    this.hot.getSourceData().map((row, index) => this.setScreenSubtypeSource(index, row.screen_type));
  }

  customizeColumns(columns, settings) {
    const dateColumns = ["start_date", "end_date", "design_asset_due_date"];

    return columns.map(column => {
      if (column.data === "unit_subtype") {
        column.editorParams = { valuesLookup: this.getMatchingSubtypes };
      } else if (column.data === "screen_subtype") {
        column.editorParams = { valuesLookup: this.getMatchingScreenSubtypes };
      } else if (dateColumns.includes(column.data)) {
        if (settings.international) {
          column.renderer = "international_date_cell";
          console.log({column})
        }
        // Check the columns returned by the backend for date columns
        // Relevant controllers:
        // - app/controllers/pro/tasks/supplier_task_base_grid_controller.rb
        // - app/controllers/pro/tasks/supplier_task_unit_details_controller.rb
      }

      return column;
    });
  }

  getMatchingSubtypes(cell) {
    const unitType = cell
      .getRow()
      .getCell("unit_type")
      .getValue();
    const mediaType = this.mediaTypesAndSubtypes.find(data => {
      return data.name.toLowerCase() === unitType.toLowerCase();
    });
    if (mediaType) {
      return mediaType.media_subtypes.map(subtype => {
        return subtype.name;
      });
    } else {
      return [];
    }
  }

  getMatchingScreenSubtypes(cell) {
    const screenType = cell
      .getRow()
      .getCell("screen_type")
      .getValue();
    const screenSubtypes = this.unitScreenSubtypes.filter(data => {
      return data.screen_type.toLowerCase() === screenType.toLowerCase();
    });

    if (screenSubtypes) {
      return screenSubtypes.map(subtype => {
        return subtype.name;
      });
    } else {
      return [];
    }
  }

  cleanupEmptyRows() {
    const data = this.hot.getSourceData();
    const emptyRows = data.filter(row => this.hasOnlyErrors(row));

    if (emptyRows.length > 0) {
      const rowIndicesToRemove = emptyRows.map(emptyRow => {
        const searchResult = this.search.query(emptyRow.row_id);
        return searchResult[0]?.row;
      }).filter(index => index !== undefined);

      if (rowIndicesToRemove.length > 0) {
        this.hot.alter('remove_row', rowIndicesToRemove[0], rowIndicesToRemove.length);
      }
    }
  }

  // temporary = true will save invalid data and stay on the page
  // temporary = false will still save but if there are no errors will redirect to the next page
  async saveData(temporary = false) {
    if (this.editedCells.length === 0 && temporary) {
      return;
    }
    const dataWithoutErrors = this.editedCells.map(({ errors, rowIndex, ...rest }) => rest);
    this.disableTable();
    const response = await put(window.location.href, { body: { data: dataWithoutErrors, temporary_save: temporary } });
    this.enableTable();
    if (response.ok) {
      // If temporary save, update the table with the new data
      // otherwise do nothing until job is done and broadcasts the turbo visit to next step
      if (temporary) {
        const { data } = await response.json;
        this.editedCells = [];
        this.deletedCells = [];
        if (data.length > 0) {
          this.hot.batch(() => {
            data.map(row => {
              const foundRow = this.search.query(row.row_id);
              if (foundRow[0]) {
                // upsertRow is needed so we dont track edits for this
                // see afterChange hook
                // make sure all backend calculated columns are also updated
                // previous code called setDataAtRowProp for each column but it was causing
                // some performance issue on the table and cells where being unresposive
                this.hot.setDataAtRowProp(foundRow[0].row, "price", row.price, "upsertRow");
                if (row.calculated_price_for_duration) {
                  this.hot.setDataAtRowProp(foundRow[0].row, "calculated_price_for_duration", row.calculated_price_for_duration, "upsertRow");
                }
                this.hot.setDataAtRowProp(foundRow[0].row, "errors", row.errors, "upsertRow");
              }
            });
          });
        }
      }

      return true;
    } else {
      console.error({ message: "Failed to save data", response });
      return false;
    }
  }

  async deleteData(rowIds) {
    if (rowIds.length === 0) {
      return;
    }
    const response = await destroy(window.location.href, { body: { data: rowIds } });
    if (response.ok) {
      const data = await response.json;
      this.deletedCells = [];
    } else {
      console.error({ message: "Failed to delete data", response });
    }
  }

  // ------ Stimulus Action
  async saveAndContinue(e) {
    // Manually disable the button to prevent double submission
    // If the form was submitted directly with turbo instead of a custom fetch this would be handled automatically
    // if the button had data-disable-with="Processing..." attribute
    const currentText = e.submitter.innerHTML;
    e.submitter.disabled = true;
    e.submitter.innerHTML = "Processing...";
    const saveResult = await this.saveData();
    if (!saveResult) {
      e.submitter.disabled = false;
      e.submitter.innerHTML = currentText;
    }
  }

  async scrollTo(e) {
    // check app/views/pro/tasks/_side_panel.html.haml for the data attributes
    const target = e.currentTarget;
    const rowId = target.dataset.rowId;
    const foundRow = this.search.query(rowId);
    if (foundRow[0]) {
      const row = foundRow[0].row;
      this.hot.selectCell(row, target.dataset.key);
    }
  }

  // END ------ Stimulus Action

  async fetchConfig() {
    // instead of window.location could also pass as a config to this controller
    // since it will only be used here I will not do it for now
    const response = await get(window.location.href, { responseKind: "json", query: { config: true } });
    if (response.ok) {
      const data = await response.json;
      return data;
    }
    throw new Error("Failed to fetch config");
  }

  async fetchMediaTypesAndSubtypes() {
    const response = await get("/api/v1/media_types/all_subtypes", { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data.data.media_types;
    }
    throw new Error("Failed to fetch unit subtypes");
  }

  async fetchUnitScreenSubtypes() {
    const response = await get("/api/v1/media_types/screen_subtypes", { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data.data.screen_subtypes;
    }
    throw new Error("Failed to fetch unit screen subtypes");
  }

  async fetchData() {
    // instead of window.location could also pass as a config to this controller
    // since it will only be used here I will not do it for now
    const response = await get(window.location.href, { responseKind: "json" });
    if (response.ok) {
      const data = await response.json;
      return data;
    }
    throw new Error("Failed to fetch data");
  }

  hasOnlyErrors(row) {
    const keys = Object.keys(omitBy(row, isNil));

    // keys.toString() hack because on javascript ['row_id'] == ['row_id'] is false, even with ===
    return keys.toString() == "row_id,errors";
  }

  disableTable() {
    this.hot.updateSettings({ readOnly: true });
  }

  enableTable() {
    this.hot.updateSettings({ readOnly: false });
  }
}
