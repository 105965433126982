// import "controllers"
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

// import { Application } from "stimulus"
// import { definitionsFromContext } from "stimulus/webpack-helpers"

// const application = Application.start()
// const context = require.context("./controllers", true, /_controller\.js$/)
// application.load(definitionsFromContext(context))

/* import "babel-core/register"; */
// import 'babel-polyfill'

import { Application } from "stimulus";

import "photoswipe/style.css";
import "tabulator-tables/dist/css/tabulator_simple.min.css";
import { PhotoGalleryController } from "./controllers/photo_gallery_controller";
import StimulusReflex from "stimulus_reflex";
import consumer from "./channels/consumer";

// import controllers
import { AddInventoryController } from "./controllers/supplier_tasks/add_inventory_controller";
import { AnimateStreamController } from "./controllers/animate_stream_controller";
import { AutoClickController } from "./controllers/auto_click_controller";
import { LazyFrameController } from "./controllers/lazy_frame_controller";
import { Autocomplete } from "stimulus-autocomplete";
import { ClearInnerController } from "./controllers/clear_inner_controller";
import { CloseAfterSubmitController } from "./controllers/close_after_submit_controller";
import { CreativeLibraryController } from "./controllers/creative_library_controller";
import { DialogController } from "./controllers/dialog_controller";
import { DisableSubmitValidationController } from "./controllers/disable_submit_validation_controller";
import { ExternalApiResultsShowController } from "./controllers/external_api_results_controller.js";
import { InventoryHealthReportTable } from "./controllers/inventory_health_report_table_controller";
import { OpenDialogController } from "./controllers/open_dialog_controller";
import { ReviewRfpController } from "./controllers/supplier_tasks/review_rfp_controller";
import { ScrollIntoView } from "./controllers/scroll_into_view_controller";
import { ScrollTo } from "./controllers/scroll_to_controller";
import { UnitDetailsController } from "./controllers/supplier_tasks/unit_details_controller";
import { UppyController } from "./controllers/uppy_controller";
import { datepicker_range } from "../../shared/planner";
import { multi_select, select } from "../../shared/plus.js";
import AccordionController from "./controllers/accordion_controller.js";
import AgencySettingsController from "./controllers/agency_settings_controller.js";
import ApplicationController from "./controllers/application_controller";
import AudienceBudgetController from "./controllers/audience_buying/budget_controller";
import AudienceCpmMaxController from "./controllers/audience_buying/cpm_max_controller";
import AudienceDatesController from "./controllers/audience_buying/dates_controller";
import AudienceHellosignController from "./controllers/audience_buying/hellosign_controller";
import AudienceRecommenderController from "./controllers/audience_recommender_controller";
import AudienceScreentTypesController from "./controllers/audience_buying/screen_types_controller";
import BillboardCostsSearchController from "./controllers/billboard_costs_search_controller.js";
import BillboardLocationsSearchController from "./controllers/billboard_locations_search_controller.js";
import CampaignRequestController from "./controllers/campaign_request_controller";
import ChangePasswordController from "./controllers/change_password_controller";
import ContentLoaderController from "./controllers/content_loader_controller.js";
import CreateAdvertiserController from "./controllers/create_advertiser_controller.js";
import CrowdfundingController from "./controllers/crowdfunding_controller.js";
import LoginSignUpController from "./controllers/login_signup_controller";
import MixpanelController from "./controllers/mixpanel_controller.js";
import OohPlannerController from "./controllers/ooh_planner_controller";
import PaginationController from "./controllers/pagination_controller.js";
import Planner from "./controllers/planner_controller";
import PlannerAiSuccessController from "./controllers/planner/ai_success_controller";
import PlannerAudienceListController from "./controllers/planner/audience_list_controller";
import PlannerMarketBudgetController from "./controllers/planner/market_budget_controller";
import PlannerMarketSearchController from "./controllers/planner/market_search_controller";
import PlannerMarketsResultsController from "./controllers/planner/markets_results_controller";
import PlannerMediaTypesController from "./controllers/planner/media_types_controller";
import PlannerReviewController from "./controllers/planner/review_controller";
import QuestionnaireController from "./controllers/users/questionnaire_controller";
import Sidenav from "./controllers/sidenav_controller";
import StimPlansShowGeographiesController from "./controllers/stim/plans/show/geographies_controller";
import SupplierMapController from "./controllers/supplier_map_controller.js";
import ToastController from "../../shared/src/controllers/toast_controller";

// import channels
import CampaignPlannerChannel from "./channels/campaign_planner_channel";

const application = Application.start();

// register controllers
application.register("agency-settings", AgencySettingsController);
application.register("autocomplete", Autocomplete);
application.register("sidenav", Sidenav);
application.register("stim--plans--show--geographies", StimPlansShowGeographiesController);
application.register("crowdfunding", CrowdfundingController);
application.register("pagination", PaginationController);
application.register("accordion", AccordionController);
application.register("content-loader", ContentLoaderController);
application.register("supplier-map", SupplierMapController);
application.register("campaign-request", CampaignRequestController);
application.register("planner", Planner);
application.register("planner-market-budget", PlannerMarketBudgetController);
application.register("planner_market_results", PlannerMarketsResultsController);
application.register("planner_audience_list", PlannerAudienceListController);
application.register("planner_market_search", PlannerMarketSearchController);
application.register("planner_review", PlannerReviewController);
application.register("planner_media_types", PlannerMediaTypesController);
application.register("audience_buying_cpm_max", AudienceCpmMaxController);
application.register("audience_buying_screen_types", AudienceScreentTypesController);
application.register("audience_buying_budget", AudienceBudgetController);
application.register("audience_buying_dates", AudienceDatesController);
application.register("audience_buying_hellosign", AudienceHellosignController);
application.register("ooh-planner", OohPlannerController);
application.register("mixpanel", MixpanelController);
application.register("login-signup-modal", LoginSignUpController);
application.register("change-password", ChangePasswordController);
application.register("datepicker-range", datepicker_range);
application.register("create-advertiser", CreateAdvertiserController);
application.register("billboard-costs-search", BillboardCostsSearchController);
application.register("billboard-locations-search", BillboardLocationsSearchController);
application.register("multi-select", multi_select);
application.register("select", select);
application.register("cco-results-show", ExternalApiResultsShowController);
application.register("audience-recommender", AudienceRecommenderController);
application.register("planner-ai-success", PlannerAiSuccessController);
application.register("questionnaire", QuestionnaireController);
application.register("creative-library", CreativeLibraryController);
application.register("toast", ToastController);
application.register("auto-click", AutoClickController);
application.register("scroll-into-view", ScrollIntoView);
application.register("scroll-to", ScrollTo);
application.register("clear-inner", ClearInnerController);
application.register("inventory-health-table", InventoryHealthReportTable);
application.register("lightbox", PhotoGalleryController);
CampaignPlannerChannel.start();
application.register("application", ApplicationController);
application.register("dialog", DialogController);
application.register("uppy", UppyController);
application.register("close-after-submit", CloseAfterSubmitController);
application.register("disable-submit", DisableSubmitValidationController);
application.register("supplier-task-add-inventory-grid", AddInventoryController);
application.register("supplier-task-unit-details-grid", UnitDetailsController);
application.register("supplier-task-review-rfp", ReviewRfpController);
application.register("animate-stream", AnimateStreamController);
application.register("open-dialog", OpenDialogController);
application.register("lazy-frame", LazyFrameController);
StimulusReflex.initialize(application, {
  consumer,
  ApplicationController,
});
StimulusReflex.debug = process.env.NODE_ENV === "development";
