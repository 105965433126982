import ApplicationController from "../../../plus/src/controllers/application_controller";

export class LazyFrameController extends ApplicationController {
  static values = {
    url: String
  }

  static targets = ["frame"]

  connected() {
    this.counter = 0;
    console.log('connected')
    this.updateSrc();
  }

  disconnected() {
    this.stopUpdating();
  }

  updateSrc() {
    this.counter++;
    this.frameTarget.setAttribute('src', this.urlValue);
    console.log(`updateSrc ${this.urlValue} ${this.counter}`)
    this.timeoutId = setTimeout(() => this.updateSrc(), 5000);
  }

  stopUpdating() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
