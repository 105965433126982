import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["searchInput", "clearButton", "dropdown", "dropdownSelectedSpan", "dropdownItem"]

  initialize() {
    this.checkInput = debounce(this.checkInput.bind(this), 500);

    this.addInputListener();
    this.updateDropdownSelectedSpan();

    document.addEventListener("stimulus-reflex:after", this.handleAfterSearch.bind(this));
  }

  disconnect() {
    document.removeEventListener("stimulus-reflex:after", this.handleAfterSearch.bind(this));
  }

  connect() {
    StimulusReflex.register(this);
  }

  handleAfterSearch(e) {
    const { detail: { reflex } } = e
    if (reflex != "Programmatic::Plan::PointsOfInterestReflex#search") { return }

    return this.checkInput()
  }

  addInputListener() {
    if (!this.hasSearchInputTarget) { return }

    this.searchInputTarget.addEventListener("input", this.checkInput);
  }

  checkInput() {
    if (this.searchInputTarget.value == "") { return this.hideClearButton() }

    return this.showClearButton()
  }

  hideClearButton() {
    this.clearButtonTarget.classList.add("hide")
  }

  showClearButton() {
    this.clearButtonTarget.classList.remove("hide")
  }

  selectedItem() {
    return this.dropdownItemTargets.find(item => item.dataset.selectedValue === "true") || { dataset: { itemLabel: "Coco" } }
  }

  unselectAllItems() {
   this.dropdownItemTargets.forEach(item => item.dataset.selectedValue = false)
  }

  selectItem(item) {
    item.dataset.selectedValue = "true";
  }

  select(e) {
    const { target } = e;
    e.preventDefault();
    this.unselectAllItems();
    this.selectItem(target.parentElement);

    return this.updateDropdownSelectedSpan();
  }

  updateDropdownSelectedSpan() {
    if (!this.hasDropdownItemTarget) { return }

    const { itemLabel } = this.selectedItem().dataset;
    this.dropdownSelectedSpanTarget.innerText = itemLabel
  }
}
